import React from "react"

import "./content-panel.css"

import { Heading2, Text } from "../common"

export default function ContentPanel({
  containerStyle = {},
  title,
  Image,
  paragraphs = [],
  reverseOrder = false,
}) {
  const reverseOrderClassName = reverseOrder
    ? "lg:flex-row-reverse lg:pl-8"
    : ""
  return (
    <div style={containerStyle}>
      <div className="container mx-auto py-4 px-4 content-panel">
        <div className={`lg:flex ${reverseOrderClassName}`}>
          <div className="flex flex-1 justify-center items-center py-6">
            <Image />
          </div>
          <div className="flex flex-col flex-1 py-6">
            <div className="p-4">
              <Heading2 className="text-4xl py-2">{title}</Heading2>
            </div>
            {paragraphs.map((paragraph, index) => (
              <div key={index} className="p-4">
                <Text>{paragraph}</Text>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
