import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 screen image dimensions
 width: 3493
 height: 2881
 width to height ratio: 1.21 (width/height)
*/

export const DailyActivitiesImage = ({ style = {} }) => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "panel-images/DailyActivities.png" }) {
        childImageSharp {
          fixed(width: 360, height: 298) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)

  return <Img fixed={data.image.childImageSharp.fixed} />
}

export default DailyActivitiesImage
