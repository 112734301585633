import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 screen image dimensions
 width: 1935
 height: 2801
 width to height ratio: 0.69 (width/height)
*/

export const TheAlgorithmImage = ({ style = {} }) => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "panel-images/TheAlgorithm.png" }) {
        childImageSharp {
          fixed(width: 300, height: 434) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)

  return <Img fixed={data.image.childImageSharp.fixed} />
}

export default TheAlgorithmImage
