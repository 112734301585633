import React from "react"

import { Heading1, Heading2, Text } from "../../components/common"
import PageHeaderImage from "../../components/PageHeaderImage"
import ContentPanel from "../../components/ContentPanel"

import SpacedRepetitionHeaderImage from "./SpacedRepetitionHeaderImage"

import StudyingTechniquesImage from "./PanelImages/StudyingTechniquesImage"
import SpacedRepetitionPanelImage from "./PanelImages/SpacedRepetitionPanelImage"
import TheAlgorithmImage from "./PanelImages/TheAlgorithmImage"
import DailyActivitiesImage from "./PanelImages/DailyActivitiesImage"

const SpacedRepetition = () => {
  return (
    <div>
      <div style={{ backgroundColor: "#b7f3e3" }}>
        <PageHeaderImage
          Image={SpacedRepetitionHeaderImage}
          title="Spaced"
          titleLineTwo="Repetition"
          headingClassName="mt-8 md:mt-16 lg:mt-30 ml-8 md:ml-24 lg:ml-32 lg:mb-2"
          headingLineTwoClassName="ml-8 md:ml-24 lg:ml-32"
          headingStyle={{
            paddingLeft: 10,
            paddingTop: 30,
            paddingBottom: 0,
          }}
          headingLineTwoStyle={{
            paddingLeft: 10,
            lineHeight: 0,
          }}
        />
      </div>
      <ContentPanel
        title="Spaced Repetition"
        Image={SpacedRepetitionPanelImage}
        paragraphs={[
          "Research and scientific studies have proven ways of studying which truly stand the test of time, the most prominent of which being spaced repetition learning. Spaced Repetition Learning is a simple but effective hack to make your brain store information you need.",
          "This learning technique incorporates increasing intervals of time between reviews of material you are learning. In simple terms, the brain stores information it deems to be important and the more it sees something, the more it acknowledges the importance of it. Thereby strengthening and consolidating memories of things it encounters regularly and frequently.",
          "With alvyApp, your time is spent learning core information that you not only understand, but retain in your brain for years to come, making learning effortless.",
        ]}
      />
      <ContentPanel
        containerStyle={{
          backgroundColor: "#d3f8ef",
        }}
        reverseOrder
        title="Self Assessment"
        Image={TheAlgorithmImage}
        paragraphs={[
          "alvyApp has been designed in a very simple way, with questions and a self assessment element. In your chosen subject, you will be shown a selection of questions, and asked to rate their difficulty. You rank your response from ‘no idea’ through to ‘nailed it’.",
          "alvyApp will work out what you already know based on the responses you give - automatically prioritising difficult questions over those you already know the answers to. ",
          "This is how alvyApp can hack the way your brain works for maximum retention of information. Your very own tailored learning tool.",
        ]}
      />
      <ContentPanel
        title="Daily Activities"
        Image={DailyActivitiesImage}
        paragraphs={[
          "Just two minutes a day is all it takes to complete an activity in alvyApp.",
          "Each daily activity is a mini brain workout and repetitive learning every day forms a habit which is easy to keep. Focusing for a just few minutes a day gives you the ability to keep up your learning and to create those connections leading to long-term memories.",
          "alvyApp has been designed to help you make smart use of your time. The benefit of having the app in your pocket means it’s convenient and fits around you and your schedule. ",
          "The app helps you get through your chosen content quickly and everyone has a spare two minutes in their hectic lives. It could be while waiting for a train, queuing for your lunch or daydreaming about the next big promotion. Which is something we could potentially help with.",
        ]}
      />
    </div>
  )
}

export default SpacedRepetition
